@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@400;700&display=swap);

@font-face {
  font-family: 'NotoSansSC400';
  src: url('/fonts/NotoSansSC-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansSC500';
  src: url('/fonts/NotoSansSC-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansSC900';
  src: url('/fonts/NotoSansSC-Bold.ttf') format('truetype');
}


@font-face {
  font-family: 'NotoSansJP400';
  src: url('/fonts/NotoSansJP-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansJP500';
  src: url('/fonts/NotoSansJP-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansJP900';
  src: url('/fonts/NotoSansJP-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansKR400';
  src: url('/fonts/NotoSansKR-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansKR500';
  src: url('/fonts/NotoSansKR-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansKR900';
  src: url('/fonts/NotoSansKR-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansTH400';
  src: url('/fonts/NotoSansThai-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansTH500';
  src: url('/fonts/NotoSansThai-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansTH900';
  src: url('/fonts/NotoSansThai-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans Arabic', sans-serif;
  src: url('/fonts/NotoSansThai-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/NotoSansKR-Light.9ea09379.woff2) format('woff2'),
       url(/static/media/NotoSansKR-Light.87f51d24.woff) format('woff'),
       url(/static/media/NotoSansKR-Light.4af942da.otf) format('truetype');
}

@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/NotoSansKR-Regular.f97524d1.woff2) format('woff2'),
       url(/static/media/NotoSansKR-Regular.af8df214.woff) format('woff'),
       url(/static/media/NotoSansKR-Regular.c3f8d68f.otf) format('truetype');
}

@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: 500;
  src: url(/static/media/NotoSansKR-Medium.bfaf1857.woff2) format('woff2'),
       url(/static/media/NotoSansKR-Medium.f3b73884.woff) format('woff'),
       url(/static/media/NotoSansKR-Medium.e68e938a.otf) format('truetype');
}

@font-face {
  font-family: 'Noto Sans CJK KR';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/NotoSansKR-Bold.d197a963.woff2) format('woff2'),
       url(/static/media/NotoSansKR-Bold.5fc6f126.woff) format('woff'),
       url(/static/media/NotoSansKR-Bold.38c81d5e.otf) format('truetype');
}
